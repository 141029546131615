import React from "react";
import Loader from "./components/loader";
import { Route, Routes, Navigate } from "react-router-dom";
import './App.css';

const LandingPage = React.lazy(() => import('./pages/landing'))
const DeliveryPage = React.lazy(() => import('./pages/delivery'))
const SuccessPage = React.lazy(() => import('./pages/success'))

const App = () => {
  return (
    <React.Suspense fallback={<Loader/>}>
      <Routes>
        <Route path='/checkout' element={<LandingPage/>}></Route>
        <Route path='/delivery' element={<DeliveryPage/>}></Route>
        <Route path='/success' element={<SuccessPage/>}></Route>
        <Route path="/" element={<Navigate to="/checkout" replace />} />
      </Routes>
    </React.Suspense>
  );
}

export default App;
